@import url('//fonts.googleapis.com/css?family=Roboto%3A300%2C400%2C500%2C700%2C900&#038;display=swap&#038;ver=1.0');

html {
  font-family: 'Roboto', sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%
}

body {
  margin: 0;
  user-select: none;
}

a {
  text-decoration: none;
}



.view-wrapper {
  padding: 0 20px 20px 20px;
  font-size: 12px;
}

.d-flex-center {
  display: flex;
  justify-content: center;
}



.time-consultation-btn.selected .circle-icon {
  color: white;
}

.appointment-step-container {
  height: 40px;
  border-radius: 5px 5px 0px 0px;
}

.appointment-step-wrapper {
  display: flex;
  justify-content: space-between;
  height: 40px;
  padding: 0 40px;
}

.appointment-step-container-step {
  color: #c2c2c2;
  margin: auto 0px;
  font-size: 12px;
  display: flex;
}

.appointment-step-container-step .circle-icon {
  font-size: 14px;
  margin: auto 0px;
}

.appointment-step-container-step .text {
  margin: auto 0px auto 5px;
}

.appointment-step-container-step.selected {
  color: white;
  font-weight: 500;
}

.appointment-list-wrapper {
  background: white;
  box-shadow: 0px 2px 10px rgb(0 0 0 / 10%);
  max-height: 500px;
  overflow: auto;
}

.continue-button,
.back-button {
  padding: 12px 10px;
  margin: 0 10px;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  user-select: none ;
  transition: transform .2s;
}

.button-text {
  margin: 0 10px 0 10px;
  font-size: 18px;
  text-align: center;
  font-weight: 700;
}


.confirm-detail-item {
  font-size: 18px;
  margin-bottom: 15px;
  font-weight: 700;
}

.title-large {
  margin-top: 30px;
  font-size: 24px;
  font-weight: bolder;
}

.powered-Wrapper {
  padding-top: 10px;
  display: flex;
  justify-content: flex-end;
}

.powered-Wrapper p {
  font-weight: bold;
  font-size: 12px;
  margin-top: 10px;
}

.logo-small {
  max-height: 25px;
  margin: 7px 0 5px 10px;
}

.optometrist-image {
  width: 100%;
  object-fit: contain;
  border-radius: 50%;
  margin-bottom: 20px;
}

.appointment-wrapper .title {
  font-size: 18px;
  font-weight: bolder;
  margin-bottom: 15px;
  margin-top: 20px;
}

.detail-item-text {
  font-size: 16px;
  line-height: 1.5em;
}

.sidebar-detail {
  margin-top: 20px;
  line-height: 1.5em;
}

.back-button:hover, .continue-button:hover, 
.check_date_list_container-time:hover, .time:hover {
  transform: scale(1.02);
}

.error-view {
  margin: 20vh 20vw;
  padding: 20px 40px;
  border: 1px solid black;
  border-radius: 5px;
}

.error-page {
  padding: 30px;
  font-size: 20px;
  font-weight: 500;
}

.date-column {
  text-align: center;
}

.time-wrapper,
.time-wrapper-empty {
  border-top: 1px solid #e0d9d9;
  padding: 8px 0;
}

.time-wrapper-empty {
  padding: 15px 0;
}

.date-header {
  padding-top: 12px;
  padding-bottom: 16px;
  text-align: center;
}

.calendar-nav {
  display: flex;
  justify-content: center;
  padding: 10px 0;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 5px;
  transition: all 0.1s ease-in-out;
}

.calendar-nav:hover {
  transform: scale(1.05);
}

.no-top-border {
  border-top: none;
}

.time-wrapper:hover {
  color: #fff;
  border-radius: 5px;
  font-weight: 700;
}

.more-times {
  text-align: center;
  padding: 5px 0;
  margin: 3px auto;
  cursor: pointer;
  color: #fff;
  border-radius: 5px;
  transition: all 0.1s ease-in-out;
  width: 50%;
}

.more-times:hover {
  transform: scale(1.05);
}

.selected-type-select {
  margin-bottom: 15px;
  padding: 5px 0;
}

.no-appointment {
  padding: 20px;
  text-align: center;
}

.next-available {
  position: absolute;
  top: 40%;
  left: 33%;
  z-index: 1000;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  display: flex;
  transition: all 0.1s ease-in-out;
  cursor: pointer;
}

.next-available:hover {
  transform: scale(1.05);
}

.detail-item {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.call-button {
  border-radius: 5px;
  color: white; 
  font-size: 20px;
  cursor: pointer;
  width: 100px;
  text-align: center;
  width: 100%;
  padding: 15px 0;
  display: flex;
  font-weight: 700;
  justify-content: center;
  transform: all 0.1 ease-in-out;
}

.call-button:hover {
  transform: scale(1.02);
}

.sub-title {
  font-size: 16px;
  font-weight: 600;
  margin: 10px 0;
}

.booking-title {
  font-size: 32px;
  font-weight: 700;
  margin: 20px 0;
  text-align: center;
}

.patient-type,
.concern-option {
  border-radius: 5px;
  padding: 15px 30px;
  margin: 0 10px;
  background-color: white;
  font-size: 15px;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  box-shadow: 0px 2px 10px rgb(0 0 0 / 10%);
  font-weight: 700;
}

.patient-type:hover,
.concern-option:hover {
 transform: scale(1.02);
}

.toggle-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
  text-align: center;
}

.concern-option {
  width: 50px;
  height: 20px;
  margin-top: 10px;
  text-align: center;
}

.type-select-item {
  margin: 5px 10px;
  padding: 10px;
  border-radius: 5px;
  transition: all 0.1s ease-in-out;
  font-weight: 500;
  font-size: 14px;
  box-shadow: 0px 2px 10px rgb(0 0 0 / 10%);
}

.type-select-item:hover {
  transform: scale(1.02);
  cursor: pointer;
}

.type-select-item.selected {
  color: #fff;
}

.selected-desc {
  font-size: 16px;
  font-weight: 600;
}

.selected-change {
  font-size: 16px;
  font-weight: 600;
  margin-left: 10px;
  cursor: pointer;
  text-decoration: underline;
}

.practitioner-bio {
  margin: 0 0 20px 10px;
  font-size: 13px;
}

.practitioner-name {
  font-weight: 600;
  font-size: 24px;
  margin: 10px;
}

.light-text {
  color: #7a7a7a;
}

.confirm-detail-value {
  font-size: 16px;
  margin-bottom: 15px;
  margin-left: 10px;
  display: block;
}

.confirm-detail {
  margin-left: 20px;
}

.page-title {
  font-size: 22px;
  font-weight: bolder;
  margin-top: 20px;
  margin-bottom: 15px;
}

.app-wrapper {
  width: 100%;
  height: 100%;
  background: #f4f4f4;
  border-radius: 5px;
}

.icon-wrapper {
  margin: 15px 20px 20px 20px;
}

.appointment-wrapper {
  margin: 40px 20px 20px 20px;
}

.booking-confirmation-wrapper {
  margin: auto;
  width: 70%;
}

.practitioner-read-bio {
  margin: 10px 30px;
  cursor: pointer;
  font-size: 16px;
  text-decoration: underline;
  font-weight: 600;
}

.patient-type-select {
  display: flex;
  justify-self: center;
}

.notification {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #fff;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0px 2px 10px rgb(0 0 0 / 10%);
  font-weight: 500;
  font-size: 16px;
  top: 10px;
  width: 300px;
}

@media screen and (max-width: 600px) {

  .page-title {
    font-size: 16px;
    font-weight: bolder;
    margin-bottom: 15px;
  }

  .confirm-detail-value {
    font-size: 14px;
    margin: 2px 10px;
    display: block;
  }

  .appointment-wrapper {
    margin: 20px 40px 20px 0px;
  }

  .booking-confirmation-wrapper {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 10px 10px 20px grey;
  }

  .booking-title {
    font-size: 24px;
    font-weight: 700;
    margin: 30px 0;
    text-align: center;
  }

  .patient-type-select {
    margin-top: 20px;
    display: inline;
  }

  .patient-type-select > div {
    margin: 10px 0;
    font-size: 18px;
  }

  .selected-appointment-type {
    margin: 0 30px;
  }

  .practitioner-bio {
    padding: 0 10px;
  }
  
  .practitioner-name {
    margin-left: 30px;
    margin-top: 20px;
  }

  .calendar-nav {
    padding: 5px 0;
  }

  .date-header {
    padding: 10px 0;
  }

  .more-times {
    width: 90%;
  }

  .practitioner-sidebar {
    text-align: center;
    margin-top: 20px;
    width: 100%;
    font-size: 20px;
  }

  .icon-wrapper {
    margin-top: 10px;
    margin-left: 10px;
  }
} 